import React, {FC} from "react";
import Carousel from 'react-bootstrap/Carousel';
import {toAbsoluteUrl} from "../../../helpers";

type Props = {
    testimonials: any
}
const Success: FC<Props> = ({testimonials}) => {
    return (
        <section className="testimonial  container-fluid ">

            <div className={'position-relative'}>
                <img src={toAbsoluteUrl("/assets/images/bg-success-darker.jpg?ver=35")} alt="Olive Branch" className="w-100"/>

                <div className={'position-absolute top-0 left-0 w-100'}>
                    <div className={'row mt-5 justify-content-center'}>
                        <div className={'col-md-8 text-center pb-3'}>
                            <h2 className={"pb-3 fs-40 text-white"}>Testimonials</h2>
                        </div>
                    </div>
                </div>

                <div className="block-center">
                    <div className={'row justify-content-center'}>
                        <div className={'col-lg-6'}>
                            <Carousel
                                interval={3000}
                                nextIcon={<span><img src={toAbsoluteUrl("/assets/images/icons/arrow-right.png?ver=55")}
                                                                  className={'testimonial-carousel-img'}
                                                                  alt="Next"/></span>}
                                prevIcon={<span><img src={toAbsoluteUrl("/assets/images/icons/arrow-left.png?ver=55")}
                                                                  alt="Previous"
                                                                  className="testimonial-carousel-img"/></span>}
                            >
                                {testimonials.map((testimonial: any) => (
                                    <Carousel.Item key={testimonial.id} className={'p-lg-1 bg-testimonial'}>
                                        <div className={'m-5'}>
                                            <div className={""}>
                                                <img src={toAbsoluteUrl('/assets/images/icons/quote.png?ver=44')} alt="Quote" className={'quote'}/>
                                                <p className={'text-white fs-18'}>
                                                    “{testimonial.description}”
                                                </p>
                                                <p className={'text-end mt-3 text-white fs-18 fw-700'}>
                                                    {testimonial.author.name}
                                                </p>
                                            </div>
                                        </div>
                                    </Carousel.Item>
                                ))}


                            </Carousel>
                        </div>
                    </div>


                </div>
            </div>


        </section>

    );
};

export default Success;
