
// export const API_URL = 'http://api.ob.test/api'
export const API_URL = 'https://api.olivebranchdubai.com/api'


export const PROPERTY_STATUS: { [key: string]: string } = {
    '2': 'Available',
    '3': 'Rented',
    '4': 'Sold',
};


