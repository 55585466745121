import React, {FC} from "react";
import {toAbsoluteUrl} from "../../../helpers";


type Props = {
    team?: any

}

const Team: FC<Props> = ({team}) => {


    return (
        <>
            <div className={' position-relative'}>
                <div className={'container pt-3 pb-5'}>
                    <div className={'row mt-5 justify-content-center'}>
                        <div className={'col-md-8 text-center pb-3'}>
                            <h2 className={"pb-3 "}>Meet the Team</h2>

                        </div>
                    </div>
                    {team && team.length > 0 &&
                        <div className={'row mt-2 justify-content-center pb-2'}>
                            {team.map((team: any) => (
                                <div className={'col-lg-1/5 col-md-6 col-8 mt-2 mb-2'} key={team.id}>
                                    <div className={'bg-team'}>
                                        <img src={`${toAbsoluteUrl(team.image)}?ver=66`} alt={team.name} className={'w-100'}/>
                                        <div className={'pt-3 pb-3'}>
                                            <h6 className={'team-name'}>{team.name}</h6>
                                            <p className={'team-position'}>{team.position.name}</p>
                                        </div>
                                    </div>
                                </div>
                            ))}


                        </div>
                    }


                </div>
            </div>
        </>
    );
};

export default Team;
