import React, {FC} from 'react';
import {Helmet} from 'react-helmet-async';
import {toAbsoluteUrl} from "../helpers";

interface Props {
    title: any;
    description?: any;
    image?: string;
    name?: string
    link?: any
    keyword?: string
}

const SocialMeta: FC<Props> = ({title, description, image, name, link, keyword}) => {
    const url = typeof window !== 'undefined' ? window.location.href : '';


    return (
        <div>

                <Helmet>
                    <title>{title}</title>
                    <meta name="description" content={description}/>
                    {keyword &&   <meta name="keywords" content={keyword} />}

                    {/* /!*Schema.org markup for Google*!/*/}
                    <meta itemProp="name" content={'Olive Branch'} />
                    <meta itemProp="description" content={description}/>
                    {image ?
                        <meta itemProp="image" content={image}/>
                        :
                        <meta itemProp="image" content="/assets/poster.jpg"/>
                    }


                    {/*/!*Twitter Card data*!/*/}
                    <meta name="twitter:card" content="summary_large_image"/>
                    <meta name="twitter:title" content={title}/>
                    <meta name="twitter:description" content={description}/>

                    {image ?
                        <meta name="twitter:image" content={image}/>
                        :
                        <meta itemProp="twitter:image" content={toAbsoluteUrl("/assets/poster.jpg")}/>
                    }

                    {image ?
                        <meta name="twitter:image:src" content={image}/>
                        :
                        <meta itemProp="twitter:image:src" content={toAbsoluteUrl("/assets/poster.jpg")}/>
                    }



                    {/*/!*Open Graph data*!/*/}
                    <meta property="og:site_name" content={'Olive Branch'}/>
                    <meta property="og:title" content={title}/>
                    <meta property="og:type" content="website"/>
                    <meta property="og:description" content={description}/>
                    {
                        image ?
                            <meta property="og:image" content={image}/>
                            :
                            <meta property="og:image" content={toAbsoluteUrl("/assets/poster.jpg")}/>
                    }
                    <meta property="og:url" content={link ? link : url}/>
                </Helmet>

        </div>
    );
};

export {SocialMeta};
