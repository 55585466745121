import React, {FC} from "react";
import {Link} from "react-router-dom";
import {toAbsoluteUrl} from "../../../helpers";

type Props = {
    article?: any
    layout?: any
    colClass?: any
    withDescription?: any
    btnClass?: any
    imageHeight?: any
    titleClass?: any
    dateClass?: any
    paddingClass?: any
    heightBlock?: any
    listingPage?: any
    bigCard?: any
}

const BlogCard: FC<Props> = ({
                                 bigCard,
                                 article,
                                 layout,
                                 colClass,
                                 withDescription,
                                 btnClass,
                                 imageHeight,
                                 titleClass,
                                 dateClass,
                                 paddingClass,
                                 heightBlock
                             }) => {

    return (
        <>
            <div className={colClass}>
                <div className={'bg-white '}>
                    <div>
                        <Link to={`/blog/${article.slug}`}>
                            <img src={`${toAbsoluteUrl(article.image)}?ver=44`} className={`w-100 ${imageHeight}`}
                                 alt={article.title.replace(/<\/?[^>]+(>|$)|&nbsp;/g, "")}/>
                        </Link>
                    </div>
                    <div className={` content-block ${paddingClass}  ${heightBlock}`}>
                        <div className={'d-flex'}>
                            <div className={'mt-1'}>
                                <img src={toAbsoluteUrl('/assets/images/icons/calendar-icon.svg?ver=44')} width={'24'} height={'24'}
                                     className={''} alt={'Olive Branch'}/>
                            </div>
                            <div className={`ms-2 ${dateClass}`}>
                                {new Date(article.created_at).toLocaleDateString('en-US', {
                                    month: 'short',
                                    day: '2-digit',
                                    year: 'numeric'
                                })}
                            </div>
                        </div>
                        <div className={'row align-items-center justify-content-between'}>
                            <div className={`${bigCard === true ? 'col-lg-7' : 'col-lg-7 me-0'}`}>
                                <div className={`${titleClass} mt-3`}>
                                    {article.title.replace(/<\/?[^>]+(>|$)|&nbsp;/g, "")}
                                </div>
                                {withDescription &&
                                    <div className={'description-sm mt-3 me-3'}>
                                        {article.description.replace(/<\/?[^>]+(>|$)|&nbsp;/g, "").substring(0, 70)}...
                                    </div>
                                }
                            </div>
                            <div
                                className={`${bigCard === true ? 'col-lg-4' : 'col-lg-5'} col-8 mt-lg-0 mt-3 mb-lg-0 mb-3`}>
                                <Link to={`/blog/${article.slug}`} className={`${btnClass} w-100`}> Read more</Link>
                            </div>
                        </div>

                    </div>
                </div>
            </div>

        </>
    );
};

export default BlogCard;
