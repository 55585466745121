import React, {FC, useEffect, useState} from "react";
import {indexProperties} from "./core/requests";
import Resale from "./components/Resale";
import {useHeaderContext} from "../../layout/context/HeaderContext";

import {SocialMeta} from "../../components/SocialMeta";

import TopBanner from "../home/components/TopBanner";


type Props = {
    data ?: any
}
const PropertyIndex: FC<Props> = ({data}) => {

    const [cityFirst, setCityFirst] = useState<any>(data?.city_first);
    const [selectedPage, setSelectedPage] = useState<number>(0);
    const [cities, setCities] = useState<any>(data?.cities || []);
    const [paginationData, setPaginationData] = useState({
        last_page:  data?.city_first?.resale_leasing?.meta?.last_page || 0,
        current_page: data?.city_first?.resale_leasing?.meta?.current_page || 0,
        total: data?.city_first?.resale_leasing?.meta?.total || 0,
    });
    const {setVariant, setLogoColor} = useHeaderContext();
    useEffect(() => {
        // Set variant to "default" when component mounts (assuming homepage needs transparent background)
        setVariant("property");
        setLogoColor("dark")
        return () => {
            // Clean up or reset when component unmounts
            setVariant("property");
            setLogoColor("dark");
        };
    }, [setVariant, setLogoColor]);
    useEffect(() => {
        if(!data){

            indexProperties(1, 9).then(response => {
                setCities(response?.data?.cities)
                setCityFirst(response?.data?.city_first)
                setPaginationData(response.data.city_first.resale_leasing.meta)
                setSelectedPage(1)
            })
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);


    return (
        <>

            <SocialMeta
                title={'Olive Branch Properties: Real Estate Specialists in Dubai'}
                description={'Find your dream property in Dubai! Explore luxury apartments, villas, & off-plan projects. Expert agents. Contact Olive Branch Properties today!'}/>

            {cities && cityFirst &&
                <Resale cities={cities} cityFirst={cityFirst} selectedPage={selectedPage} pagination={paginationData}/>}


        </>
    );
};

export default PropertyIndex;
