import React, {FC} from "react";
import ReactPaginate from "react-paginate";
import {toAbsoluteUrl} from "../helpers";

type Props = {
    pageCount: number;
    onPageChange: (selectedItem: { selected: number }) => void;
    forcePage?: number;
    marginDisplay?: number
    arrowDark?: boolean

};

const CustomPagination: FC<Props> = ({pageCount, onPageChange, forcePage, marginDisplay, arrowDark}) => {

    return (
        <ReactPaginate
            pageCount={pageCount}
            onPageChange={onPageChange}
            forcePage={forcePage}
            marginPagesDisplayed={2}
            pageRangeDisplayed={5}
            previousLabel={
                <>
                    <div className="custom-ar">
                        {arrowDark ?
                            <img src={toAbsoluteUrl('/assets/images/icons/arrow-left-dark.png?ver=44')} alt="Olive Branch Arrow"/>
                            :
                            <img src={toAbsoluteUrl('/assets/images/icons/arrow-left.png?ver=44')} alt="Olive Branch Arrow"/>}
                    </div>

                    <div className={'chev-left'}>
                        {arrowDark && <i className="fa-solid fa-chevron-left"></i>}
                    </div>

                </>

            }
            nextLabel={
                <>
                    <div className="custom-ar">
                        {arrowDark ?
                            <img src={toAbsoluteUrl('/assets/images/icons/arrow-right-dark.png?ver=44')} alt="Olive Branch Arrow"/>
                            :
                            <img src={toAbsoluteUrl('/assets/images/icons/arrow-right.png?ver=44')} alt={'Olive Branch Arrow'}/>}
                    </div>

                    <div className={'chev-right'}>
                        {arrowDark && <i className="fa-solid fa-chevron-right"></i>}
                    </div>
                </>

            }
            breakLabel={"..."}
            containerClassName={"pagination"}
            activeClassName={"active"}
        />
    );
};

export default CustomPagination;
