/**
 * High level router.
 *
 * Note: It's recommended to compose related routes in internal router
 * components (e.g: `src/app/modules/Auth/pages/AuthPage`, `src/app/BasePage`).
 */

import React, {FC} from 'react'
import { Navigate, Route, Routes} from 'react-router-dom'
import Blog from "../pages/blog/Blog";
import BlogShow from "../pages/blog/BlogShow";
import PropertyDetails from "../pages/property/Property";
import Page404 from "../pages/404";
import Contact from "../pages/contact/Contact";
import TagBlogs from "../pages/blog/TagBlogs";
import Home from "../pages/home/Home";
import PropertyIndex from "../pages/property/PropertyIndex";
import ListYourProperty from "../pages/property/ListYourProperty";
import PropertyShow from "../pages/property/PropertyShow";
import Faqs from "../pages/faq/Faqs";
import About from "../pages/about/About";
import Privacy from "../pages/privacy/privacy";


/**
 * Base URL of the website.
 *
 * @see https://facebook.github.io/create-react-app/docs/using-the-public-folder
 */
type Props = {
    data ?: any
}

const AppRoutes: FC<Props> = ({data}) => {
    return (
        <Routes>
            <Route path='/' element={<Home data={data} />}/>
            <Route path="/blog" element={<Blog data={data}/>}/>
            <Route path="/blog/:slug" element={<BlogShow data={data}/>}/>
            <Route path="/blog/tag/:slug" element={<TagBlogs data={data}/>}/>
            <Route path="/properties" element={<PropertyIndex data={data}/>}/>
            <Route path="/properties/:slug" element={<PropertyShow data={data}/>}/>
            <Route path="/property/:slug" element={<PropertyDetails data={data}/>}/>
            <Route path="/contact" element={<Contact data={data}/>}/>
            <Route path="/faqs" element={<Faqs data={data}/>}/>
            <Route path="/about" element={<About data={data}/>}/>
            <Route path="/privacy-policy" element={<Privacy/>}/>
            <Route path="/list-your-property" element={<ListYourProperty data={data}/>}/>
            <Route path="*" element={<Page404/>}/>

        </Routes>



    )
}

export {AppRoutes}
