import React from "react";
import {toAbsoluteUrl} from "../../../helpers";

const Mission: React.FC = () => {
    return (
        <div className="container py-80">
            <div className="pattern position-absolute right-0 w-150px">
                <img className="w-100"
                     src={toAbsoluteUrl('/assets/images/patterns/pattern-1.svg?v=123')} alt={'Olive Branch Pattern'}/>
            </div>

            <div className="row align-items-center position-relative">
                <div className="col-xl-10 col-lg-10 col-lg-8 col-sm-12 col-12 col-centered">
                    <div className="row d-flex align-items-center">
                        <div className="col-xl-5 col-lg-5 col-md-5 col-12 d-flex justify-content-center">
                            <div className="w-100">
                                <img src={toAbsoluteUrl("/assets/images/about-mission.png?v=123")} className="w-100"
                                     alt="Our Mission"/>
                            </div>
                        </div>
                        <div className="col-xl-2 col-lg-2 col-md-2 col-12 p-0"></div>
                        <div className="col-xl-5 col-lg-5 col-md-5 col-12 w-mt-sm-25px">
                            <h2 className="fs-40 text-black">Our Mission</h2>
                            <div className="dark-light-color fs-16 lh-25 me-lg-5 me-0 pt-3 mb-4 f-main-medium">
                                <p>
                                    At Olive Branch Properties, our mission is to provide exceptional, tailored
                                    services that meet
                                    each client's unique needs. We pride ourselves on our customer-centric approach,
                                    offering expert advice and ensuring our
                                    clients make well informed decisions.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Mission;
