import React, {FC, useEffect, useState} from "react";
import {showBlog} from "../../core/home/requests";
import TopBanner from "../home/components/TopBanner";
import {Link, useParams} from "react-router-dom";
import BlogCard from "../home/components/BlogCard";
import Contact from "./components/Contact";
import {toAbsoluteUrl} from "../../helpers";
import {SocialMeta} from "../../components/SocialMeta";
import {useHeaderContext} from "../../layout/context/HeaderContext";

type Props = {
    data ?: any
}
const BlogShow: FC<Props> = ({data}) => {
    const params = useParams()
    const [blog, setBlog] = useState<any>(data?.blog || {});
    const [blogs, setBlogs] = useState<any>(data?.blogs?.data || []);
    const [relatedBlogs, setRelatedBlogs] = useState<any>(data?.relatedBlogs?.data || []);
    const {setVariant, setLogoColor} = useHeaderContext();
    useEffect(() => {
        // Set variant to "default" when component mounts (assuming homepage needs transparent background)
        setVariant("property");
        setLogoColor("dark")
        return () => {
            // Clean up or reset when component unmounts
            setVariant("property");
            setLogoColor("dark");
        };
    }, [setVariant, setLogoColor]);

    useEffect(() => {
        if(!data){
            showBlog(params.slug).then(response => {
                setBlog(response.data.blog)
                setBlogs(response.data.blogs.data)
                setRelatedBlogs(response.data.relatedBlogs.data)

            })
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [params.slug]);


    return (
        <>
            <SocialMeta
                image ={toAbsoluteUrl(blog.image)}
                title={blog && blog.meta_title ? blog.meta_title : 'Olive Branch Properties: Real Estate Specialists in Dubai'}
                description={blog && blog.meta_description ? blog.meta_description : 'Find your dream property in Dubai! Explore luxury apartments, villas, & off-plan projects. Expert agents. Contact Olive Branch Properties today!'}/>

            {blog &&
                <>
                    {blog.title && <TopBanner title={'<div class="container"><div class="row"><div class="col-md-12"> <h1 class="blog-title title-2">' +  blog.title  + '</h1></div> </div></div>'}/>}
                    <div className={'bg-primary-light'}>
                        <div className={'container'}>
                            <div className={'row justify-content-center pt-5'}>
                                <div className={'col-xl-8 col-lg-12 col-md-12  pt-2 pb-4'}>
                                    <div className={'bg-white'}>
                                        <div>
                                            <img src={`${toAbsoluteUrl(blog.image)}?ver=3`} className={'w-100'}
                                                 alt={'Olive Branch Blog'}/>
                                        </div>
                                        <div className={'article-block'}>

                                            <div className={'d-flex'}>
                                                <div className={'mt-1'}>
                                                    <img src={toAbsoluteUrl('/assets/images/icons/calendar-icon.svg?ver=55')} width={'24'}
                                                         height={'24'} className={''} alt={'Olive Branch Icon'}/>
                                                </div>
                                                <div className={`ms-2 date sm`}>
                                                    {new Date(blog.created_at).toLocaleDateString('en-US', {
                                                        month: 'short',
                                                        day: '2-digit',
                                                        year: 'numeric'
                                                    })}
                                                </div>
                                            </div>
                                            {/*<div className={`title-smm mt-3`}*/}
                                            {/*     dangerouslySetInnerHTML={{__html: blog.title}}/>*/}

                                            <div className={'mt-3'}>
                                                <div className={'article-description'}
                                                     dangerouslySetInnerHTML={{__html: blog.description}}/>
                                            </div>

                                            {blog?.tags && blog?.tags.length > 0 &&
                                                <div className={'row mt-4'}>
                                                    <div className={'col-md-12'}>
                                                        <ul className={'tags'}>
                                                            {blog?.tags.map((tag: any) => (
                                                                <li className={'mt-lg-1 mt-3'} key={tag.id}>
                                                                    <Link to={`/blog/tag/${tag.slug}`}>{tag.name}</Link>
                                                                </li>
                                                            ))}

                                                        </ul>
                                                    </div>
                                                </div>
                                            }


                                            {blog && blog.author &&
                                                <div className={'mt-5'}>
                                                    <div className={'row'}>
                                                        {blog.author.image &&
                                                            <div className={'col-md-3'}>
                                                                <div className={'bg-t-ch'}>
                                                                    <img src={toAbsoluteUrl(blog.author.image)}
                                                                         className={'w-100 img-t-ch'}
                                                                         alt={'Olive Branch Author'}/>
                                                                </div>
                                                            </div>
                                                        }
                                                        <div className={'col-md-9'}>
                                                            <div className={'name'}>
                                                                {blog.author.name}
                                                            </div>
                                                            <div className={'last-name'}>
                                                                {blog.author.title}
                                                            </div>
                                                            <div className={'th-description'}
                                                                 dangerouslySetInnerHTML={{__html: blog.author.description}}/>


                                                        </div>
                                                    </div>
                                                </div>
                                            }

                                            {/*<Comment blog={blog}/>*/}
                                        </div>
                                    </div>
                                </div>

                                <div className="col-xl-4 col-lg-12 col-md-12  pt-2 pb-4">
                                    <div className={''}>
                                        <div className={''}>
                                            <h2 className={"pb-3"}>Latest Articles</h2>
                                        </div>
                                    </div>
                                    {blogs && blogs.length > 0 &&
                                        <div className="row">
                                            {blogs.slice(0, 3).map((article: any, index: any) => (
                                                <BlogCard
                                                    key={index + 3}
                                                    article={article}
                                                    btnClass="btn btn-primary-inverse"
                                                    dateClass="date sm"
                                                    titleClass="title-sm"
                                                    colClass="col-xl-12 col-lg-12 col-md-12 mb-3"
                                                    withDescription={false}
                                                    imageHeight="h-200px"
                                                    paddingClass="sm"
                                                    heightBlock="h-160px"
                                                />
                                            ))}
                                        </div>
                                    }

                                    <div className={'row'}>
                                        <div className={'mt-3'}>
                                            <div className={'bg-white bdr-all'}>
                                                <div className={'bg-green-light bdr-t p-3'}>
                                                    <div className={'contact-title'}>
                                                        Let's have a conversation!
                                                    </div>
                                                    <div className={'contact-sm'}>
                                                        Call or Chat here with us:
                                                    </div>
                                                </div>
                                                <div>
                                                    <div className={'row mt-3 '}>
                                                        <div className={'col-lg-4 col-4 ms-3 mb-3'}>
                                                            <img className={'w-100'}
                                                                 src={'/assets/images/icons/contact-icon.png'}
                                                                 alt={'Olive Branch Icon'}/>
                                                        </div>
                                                        <div className={'col-lg-6 col-6 mt-2'}>
                                                            <div>

                                                                <a className={'btn btn-primary sm call w-100'}
                                                                   href={`tel:${'971507173741'}`}> <img
                                                                    className={'me-2 icon-sm '}
                                                                    alt={'Olive Branch Icon'}
                                                                    src={'/assets/images/icons/phone.png'}/> Call
                                                                                                             now</a>


                                                            </div>
                                                            <div>

                                                                <a className={'btn btn-light-green mt-2 w-100'}
                                                                   href={`whatsapp://send?phone=${'971507173741'}&text=Hello, I would like to get more information about the properties `}>
                                                                    <img className={'me-2 icon-sm'}
                                                                         alt={'Olive Branch Icon'}
                                                                         src={'/assets/images/icons/whats.png'}/> whatsapp
                                                                </a>


                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>


                                        </div>
                                        <div className={'col-md-12'}>
                                            <Contact/>
                                        </div>

                                    </div>
                                </div>

                            </div>
                            {relatedBlogs.length > 0 &&
                                <>
                                    <div className={'row justify-content-center pt-2'}>
                                        <div className={'col-md-12 text-center'}>
                                            <h2 className={"pb-3"}>Related Posts</h2>
                                        </div>
                                    </div>

                                    <div className="row pb-4">
                                        {relatedBlogs.slice(0, 3).map((article: any, index: any) => (
                                            <BlogCard
                                                key={index + 3}
                                                article={article}
                                                btnClass="btn btn-primary-inverse"
                                                dateClass="date sm"
                                                titleClass="title-sm"
                                                colClass="col-xl-4 col-lg-4 col-md-12 mb-3"
                                                withDescription={false}
                                                imageHeight="h-200px"
                                                paddingClass="sm"
                                                heightBlock="h-160px"
                                            />
                                        ))}

                                    </div>
                                </>
                            }

                        </div>
                    </div>

                </>
            }


        </>
    )
}
export default BlogShow;