import React, {FC} from "react";
import {toAbsoluteUrl} from "../../../helpers";

type Props = {
    roadmap?: any
}

const Roadmap: FC<Props> = ({roadmap}) => {
    return (
        <section className="bg-grey" id="roadmap">
            <div className="container">
                <div className="row">
                    <div className="col-md-12 ">
                        <div className="pt-5">
                            <h2 className={"pb-4 text-center fs-40 text-black pt-4"}>Roadmap to your Real Estate portfolio</h2>

                            <div className="row  mt-4 mb-5 pb-5">
                                {roadmap && roadmap.length > 0 && roadmap.map((block: any) => (
                                    <div className="col-lg-4 col-12" key={block.id}>
                                        <div className="card card-custom gutter-b example example-compact card-stretch hover-opacity">
                                            <img src={toAbsoluteUrl('/assets'+block.image)} alt="We advise" className="w-100"/>
                                            <div className="p-4">
                                                <p className={'fs-24 mb-lg-3 mb-1'}>{block.title}</p>
                                                <small>
                                                    {block.description}
                                                </small>
                                            </div>
                                        </div>
                                    </div>
                                ))}


                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </section>
    );
};

export default Roadmap;



