import React, {FC, useState} from "react";
import {useFormik} from "formik";
import * as Yup from "yup";
import clsx from "clsx";
import {contact} from "../../../core/home/requests";
import LazyLoad from "react-lazyload";
import Select from "react-select";
import {toAbsoluteUrl} from "../../../helpers";

const initialValues = {
    email: "",
    name: "",
    message: "",
    type: 3,
    subject: ""
};

const contactSchema = Yup.object().shape({
    email: Yup.string()
        .email("Wrong email format")
        .min(3, "Minimum 3 symbols")
        .max(50, "Maximum 50 symbols")
        .required("Email is required"),
    name: Yup.string().required("Name is required"),
    subject: Yup.string().required("Subject is required"),
    message: Yup.string().required("Message is required"),
});

type Props = {
    subjects: any

}
const Contact: FC<Props> = ({subjects}) => {


    const [loading, setLoading] = useState(false);
    const [isActive, setIsActive] = useState(false);
    const formik = useFormik({
        initialValues,
        validationSchema: contactSchema,
        onSubmit: async (values, {setSubmitting}) => {
            setSubmitting(true);
            try {
                setLoading(true);
                //
                const {data} = await contact(values);
                if (data === 1) {
                    setLoading(false);
                    setIsActive(true);
                    formik.resetForm();
                    setTimeout(function () {
                        setIsActive(false);
                        // resetForm()
                    }, 3500);
                }
            } catch (ex) {
                console.error(ex);
            } finally {
                setSubmitting(true);
                setLoading(false);
            }
        },
    });
    return (
        <section id="contact-section" className="border-bottom position-relative">
            <div className=" container">
                <div className="row align-items-center mt-lg-1 mt-5 mb-5">
                    <div className="col-lg-6 col-md-12  mt-lg-5 mt-4">
                        <img src={toAbsoluteUrl('/assets/images/call-overlay.png?ver=33')} alt="Olive Branch" className="overlay-left d-lg-block d-md-none d-none"/>
                        <LazyLoad offset={100}
                                  placeholder={<span
                                      className="spinner-border spinner-border-sm align-middle ms-2 text-dark"></span>}>

                            <img
                                src={toAbsoluteUrl('/assets/images/call-image.jpg?ver=44')}
                                alt="Olive Branch"
                                className="w-100 bdr-left-lg"
                            />
                        </LazyLoad>
                    </div>
                    <div className="col-lg-6 col-md-12  mt-lg-5 mt-4">
                            <h2 className="fs-40 text-black">Contact Us</h2>
                            <div className="row mt-3 w-100">
                                <div className={'col-md-12'}>
                                    <div className={` ${isActive ? " alert-box d-block" : "alert-box d-none"}`}>
                                        <div className="alert alert-success alert-dismissible fade show" role="alert">
                                            Successfully Done
                                        </div>
                                    </div>
                                </div>

                            </div>
                            <form className="z-index-999"
                                  onSubmit={formik.handleSubmit}
                                  noValidate>
                                <div className="row mt-1">
                                    <div className="col-md-12">
                                        <label className="form-label">Name</label>
                                        <input type="text"  {...formik.getFieldProps("name")}
                                               className={clsx(
                                                   "form-control",
                                                   {
                                                       "is-invalid":
                                                           formik.touched.name && formik.errors.name,
                                                   },
                                                   {
                                                       "is-valid":
                                                           formik.touched.name && !formik.errors.name,
                                                   })} name="name"/>
                                    </div>
                                    {formik.touched.name && formik.errors.name && (
                                        <div className="fv-plugins-message-container">
                                            <span role="alert">{formik.errors.name}</span>
                                        </div>
                                    )}
                                    <div className="col-md-12 mt-3">
                                        <label className="form-label">Email</label>
                                        <input type="email"

                                               {...formik.getFieldProps("email")}
                                               className={clsx(
                                                   "form-control",
                                                   {
                                                       "is-invalid":
                                                           formik.touched.email && formik.errors.email,
                                                   },
                                                   {
                                                       "is-valid":
                                                           formik.touched.email && !formik.errors.email,
                                                   })} name="email"/>
                                    </div>

                                    {formik.touched.email && formik.errors.email && (
                                        <div className="fv-plugins-message-container">
                                            <span role="alert">{formik.errors.email}</span>
                                        </div>
                                    )}


                                    {subjects && subjects.length > 0 &&

                                        <div className={'col-md-12 mt-3'}>
                                            <label className="form-label">Subject</label>
                                            <Select
                                                className={clsx("h-none w-100",
                                                    {
                                                        "invalid-select":
                                                            formik.touched.subject && formik.errors.subject
                                                    },
                                                    {
                                                        "valid-select":
                                                            formik.touched.subject && !formik.errors.subject
                                                    },
                                                )}
                                                closeMenuOnSelect={true}
                                                placeholder={"Select Subject"}
                                                options={subjects.map((e: {
                                                    name: any;
                                                    slug: any
                                                }) => ({label: e.name, value: e.slug}))}
                                                isSearchable={true}
                                                onChange={(newValue: any) =>
                                                    formik.setFieldValue('subject', newValue.value)
                                                }

                                            />
                                            {formik.touched.subject && formik.errors.subject && (
                                                <div className="fv-plugins-message-container">
                                                    <span role="alert">{formik.errors.subject}</span>
                                                </div>
                                            )}
                                        </div>
                                    }


                                    <div className="col-md-12 mt-3">
                                        <label className="form-label">Message</label>
                                        <textarea    {...formik.getFieldProps("message")}
                                                     className={clsx(
                                                         "form-control h-150",
                                                         {
                                                             "is-invalid":
                                                                 formik.touched.message && formik.errors.message,
                                                         },
                                                         {
                                                             "is-valid":
                                                                 formik.touched.message && !formik.errors.message,
                                                         })} name="message"/>
                                    </div>

                                    {formik.touched.message && formik.errors.message && (
                                        <div className="fv-plugins-message-container">
                                            <span role="alert">{formik.errors.message}</span>
                                        </div>
                                    )}

                                    <div className="col-lg-4 col-6 mt-3">

                                        <button className="btn btn-black p-40 w-100"
                                                disabled={formik.isSubmitting || !formik.isValid}
                                                type="submit">{!loading && (
                                            <span className="indicator-label">
                                  Submit
                                </span>
                                        )}
                                            {loading && (
                                                <span
                                                    className="indicator-progress"
                                                    style={{display: "block"}}
                                                >
                                  <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                                </span>
                                            )}</button>
                                    </div>

                                </div>

                            </form>

                    </div>
                </div>
            </div>
        </section>
    );
};

export default Contact;
